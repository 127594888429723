<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Edit Holiday Group</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'holiday-groups'}">
                        <span>Holiday Groups</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Edit</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form v-on:submit.prevent="updateHolidayGroup()">
                    <div class="form-group row">
                        <label class="col-md-2">
                            Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>

                        <div class="col-md-10">
                            <input
                                v-model="holiday_group"
                                type="text"
                                class="form-control"
                                @change="textTitleCase($event)"
                                aria-describedby="holidaygroupHelpInline"
                                autocomplete="off"
                            />
                        </div>
                    </div>
                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="updateHolidayGroup()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                @click="$router.go(-1)"
                                class="btn btn-secondary ma-10px"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            holiday_group:""
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_HOLIDAYS");
        this.getHolidayGroup();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event) {
            this.holiday_group = this.strTitleCase(event.target.value);
        },

        getHolidayGroup() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                id: this.$route.params.id
            };
            this.axios.get(this.$api.get_holiday_groups, query).then(response => {
                this.holiday_group = response.data.data.name;
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        updateHolidayGroup() {
            this.startProgressBar();
            const data = {
                name: this.holiday_group,
                id: this.$route.params.id
            };
            this.axios
            .post(this.$api.update_holiday_group, data, this.getAuthHeaders())
            .then(() => {
                this.updateProgressBar(true);
                this.$router.push({name: 'holiday-groups'});
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        }
    }
};
</script>
<style lang="scss">
</style>
